import {
  DataPoint,
  HStack,
  TableWidget,
  Token,
  Text,
  MoreBar,
  TextSkeleton,
  Icon,
  useStatusPopup,
  StatusPopup,
  MoreBarSkeleton,
  Flex,
} from '@revolut/ui-kit'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalEntityDefinition,
  ManageGoalsPayload,
  fetchGoalGraph,
  goalsListTableRequests,
  manageGoals,
} from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsCurrentValueColumn,
  goalsInitialValueColumn,
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsStrategyColumn,
  goalsTargetColumns,
  goalsTypeColumn,
  goalsUnitColumn,
  goalsWeightColumn,
  goalsStatusColumn,
} from '@src/constants/columns/goals'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GoalWeightMode, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { captureException } from '@sentry/react'
import ConfirmationDialog from '../Popups/ConfirmationDialog'
import { roundFloat } from '@src/utils/numbers'
import { Statuses } from '@src/interfaces'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
}

export const GoalsTableWidget = ({ initialFilters }: GoalsTableWidgetProps) => {
  const { entity } = useOrgEntity()
  const defaultWeightMode =
    (entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode?.id) ||
    'automatic'
  const [weightMode, setWeightMode] = useState<GoalWeightMode>(defaultWeightMode)
  const table = useTable(goalsListTableRequests, initialFilters)
  const form = useLapeContext<ManageGoalsPayload>()
  const statusPopup = useStatusPopup()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const totalWeight = form.values.goals.map(g => g.weight).reduce((a, b) => a + b, 0)
  const manageMode = weightMode === 'manual'

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  const goalsIdsToApprove = table.data
    .filter(({ approval_status }) => approval_status.id !== ApprovalStatuses.Approved)
    .map(({ id }) => ({ id }))

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(
    () => ({
      linkToForm:
        weightMode === 'automatic'
          ? (data, parentIndexes) => {
              const goal =
                parentIndexes && parentIndexes.length
                  ? table.data[parentIndexes[0]]
                  : data

              if (goal) {
                const route =
                  goal.status.id === Statuses.draft
                    ? ROUTES.FORMS.GOAL.EDIT
                    : ROUTES.FORMS.GOAL.PREVIEW

                const url = pathToUrl(
                  route,
                  { id: goal.id },
                  filterSortPageIntoQuery(undefined, table.filterBy) as Record<
                    string,
                    string
                  >,
                )

                navigateTo(url)
              }
            }
          : undefined,
      noChildrenRequest: true,
      cells: [
        {
          ...goalsNameColumn(table.filterBy),
          width: 300,
        },
        {
          ...goalsInitialValueColumn,
          width: 50,
        },
        {
          ...goalsCurrentValueColumn,
          width: 50,
        },
        {
          ...goalsTargetColumns,
          width: 50,
        },
        {
          ...goalsUnitColumn,
          width: 50,
        },
        {
          ...goalsStrategyColumn,
          width: 50,
        },
        {
          ...goalsTypeColumn,
          width: 50,
        },
        {
          ...(manageMode && weightMode === 'manual'
            ? goalsInputWeightColumn
            : goalsWeightColumn),
          width: 70,
        },
        {
          ...goalsStatusColumn,
          width: 100,
        },
        {
          ...goalsProgressColumn,
          width: 50,
        },
        {
          ...goalsOwnerColumn,
          width: 150,
        },
      ].filter(Boolean),
    }),
    [manageMode, table.data, weightMode],
  )

  const getManageGoalsEntityDefinition = (
    contentType?: any,
  ): ManageGoalEntityDefinition | null => {
    if (!entity) {
      return null
    }

    if (entity.type === EntityTypes.company) {
      return {
        is_company: true,
      }
    }
    if (contentType) {
      return { content_type: { id: contentType }, object_id: entity.data.id }
    }
    return null
  }

  const handleSubmit = async (autodistribute?: boolean) => {
    const values = form.values
    const contentType = table.data[0].content_type?.id
    const entityDefinition = getManageGoalsEntityDefinition(contentType)

    if (!entityDefinition) {
      captureException('Failed to determine entity definition for manage goals action')
      throw new Error('Please reload page and try again.')
    }

    const autodistributesWeights = autoDistributeWeights()

    const updatedForm = {
      ...form.values,
      ...entityDefinition,
      goal_weight_mode: autodistribute
        ? { id: 'automatic' as const }
        : { id: 'manual' as const },
      goals: values.goals.map(({ id, weight }, index) => ({
        id,
        weight: autodistribute ? autodistributesWeights[index] : weight,
      })),
    }

    try {
      await manageGoals(updatedForm)

      table.refresh()

      if (autodistribute) {
        setWeightMode('automatic')
      }

      return updatedForm
    } catch (err) {
      captureException(err)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to update weights</StatusPopup.Title>
          <StatusPopup.Description>
            Something went wrong, please try again later.
          </StatusPopup.Description>
        </StatusPopup>,
      )

      throw err
    }
  }

  const autoDistributeWeights = (): number[] => {
    let sum = 0
    const count = table.data.length
    const roundedWeight = roundFloat(100 / count, 2)
    const minIncrement = 0.01
    const diff = roundFloat(100 - roundedWeight * count, 2) / minIncrement

    return table.data.map((goal, index) => {
      if (index < diff) {
        const weight = roundedWeight + minIncrement

        sum += weight

        return weight
      }
      if (goal === table.data.at(-1)) {
        return roundFloat(100 - sum, 2)
      }

      sum += roundedWeight

      return roundedWeight
    })
  }

  const manageAction = manageMode ? (
    <MoreBar.Action useIcon="AutoExchange" onClick={() => setConfirmOpen(true)}>
      Auto-distribute weights
    </MoreBar.Action>
  ) : (
    <MoreBar.Action useIcon="CandlestickChart" onClick={() => setWeightMode('manual')}>
      Set custom weights
    </MoreBar.Action>
  )

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <DataPoint>
            <DataPoint.Value color={Token.color.greyTone50}>
              {entity && table.stats?.overall_progress !== undefined ? (
                <HStack space="s-8" align="center">
                  <Text use="h5">{Math.round(table.stats.overall_progress * 100)}%</Text>
                  <GraphIconChart
                    id={entity.data.id}
                    vertical="right"
                    fetchData={fetchGoalGraph}
                  >
                    <Icon
                      name="BarChart"
                      size={16}
                      style={{ cursor: 'pointer ' }}
                      color={Token.color.greyTone50}
                    />
                  </GraphIconChart>
                </HStack>
              ) : (
                <TextSkeleton />
              )}
            </DataPoint.Value>
            <DataPoint.Label>Overall Progress</DataPoint.Label>
          </DataPoint>
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
            filterInputType={FilterSelectType.SingleSelect}
          />
        </TableWidget.Info>
        <TableWidget.Search>
          <SearchTable
            placeholder="Search by name"
            onFilter={table.onFilterChange}
            ml={0}
          />
        </TableWidget.Search>
        <TableWidget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoalAction />
              {table.data.length && manageAction}
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              childreOpenByDefault
              dataFieldName="goals"
              expandableType="chevron"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              replaceOnInitialDataChange
              allowChildren
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              childreOpenByDefault
              expandableType="chevron"
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
            />
          )}

          {manageMode &&
          roundFloat(totalWeight, 2) !== 100 &&
          !table.loading &&
          table.data.length ? (
            <Flex alignItems="center" mt="s-16" gap="s-8">
              <Icon name="ExclamationMarkOutline" color={Token.color.orange} size={16} />
              <Text color={Token.color.greyTone50} variant="caption">
                Custom weights defined - your weights don’t add up to 100%. Current value
                is {roundFloat(totalWeight, 2)}%
              </Text>
            </Flex>
          ) : null}
        </TableWidget.Table>
      </TableWidget>
      {manageMode && form.dirty && (
        <PageActions mt="s-32" maxWidth="100%">
          <NewSaveButtonWithPopup
            onClick={() => handleSubmit()}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      <ConfirmationDialog
        variant="compact"
        open={confirmOpen}
        label="Confirm auto distribution of weights"
        body="This will equally distribute the goals weights and overwrite the current values."
        yesMessage="Continue"
        noMessage="Cancel"
        onClose={() => setConfirmOpen(false)}
        onReject={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false)
          handleSubmit(true)
        }}
      />
    </>
  )
}

export const GoalsTableFormWidget = ({
  initialFilters,
}: {
  initialFilters: FilterByInterface[]
}) => {
  const { entity } = useOrgEntity()

  const initialValues = useMemo(() => {
    return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
      ? {
          goals: [],
          goal_weight_mode: entity.data.goal_weight_mode,
        }
      : {
          goals: [],
          goal_weight_mode: { id: 'automatic' },
        }
  }, [entity])

  return (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialValues}
    >
      <GoalsTableWidget initialFilters={initialFilters} />
    </LapeForm>
  )
}
