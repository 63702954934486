import { Box, Flex, TabBar, TabBarProps, Text } from '@revolut/ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { matchPath, useLocation } from 'react-router-dom'
import { getPrevLocationDescriptor } from '@src/actions/RouterActions'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { css } from 'styled-components'
import TabBarSkeleton from '@components/Skeletons/TabBarSkeleton'
import TabBarLink from '@components/TabBarLink/TabBarLink'

export interface TabBarTableNavigationProps extends TabBarProps {
  tabs?: TabBarTableNavigationInterface[]
  loading?: boolean
  isDisabled?: boolean
  disabledTooltip?: React.ReactNode
  isSmall?: boolean
  isSubtab?: boolean
  fullWidth?: boolean
}

const TooltipCss = css`
  display: block;
  max-width: 100%;
`

const TabBarNavigation = ({
  tabs,
  isSubtab,
  loading,
  isDisabled,
  disabledTooltip,
  isSmall,
  fullWidth,
  ...rest
}: TabBarTableNavigationProps) => {
  const location = useLocation()
  const [key, setKey] = useState(0)
  const ref = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if ((document as any).fonts) {
      ;(document as any).fonts.ready.then(() => {
        setKey(Math.random())
      })
    }
  }, [])

  if (!tabs?.length || loading) {
    return (
      <Box height={44}>
        <TabBarSkeleton />
      </Box>
    )
  }

  return (
    <Tooltip
      hide={!isDisabled || !disabledTooltip}
      css={TooltipCss}
      body={
        <Box minWidth={150} p="s-12" color="background">
          {disabledTooltip}
        </Box>
      }
      anchor={ref.current || undefined}
      placement="bottom"
      fullWidth={fullWidth}
    >
      <TabBar
        key={key}
        variant={isSubtab ? 'segmented fit' : 'navigation'}
        behaviour="scroll"
        maxWidth="min-content"
        {...rest}
      >
        {tabs.map(tab => {
          let match: boolean | undefined
          if (tab.path) {
            match = !!matchPath(location.pathname, {
              path: tab.path,
              exact: true,
            })
          }
          const tabDisabled = isDisabled || tab.disabled

          return (
            <TabBarLink
              aria-selected={match === undefined ? undefined : match}
              exact
              disabled={tabDisabled}
              to={getPrevLocationDescriptor((tab.to || tab.url) as string)}
              key={
                getPrevLocationDescriptor((tab.to || tab.url) as string)?.pathname ||
                tab.key
              }
              style={tabDisabled ? { pointerEvents: 'none' } : undefined} // For some reason "disabled" prop doesnt disable pointer events
              ref={itemRef => {
                if (match) {
                  ref.current = itemRef
                }
              }}
              data-event-key="tabbar.item"
            >
              <Flex
                style={{
                  transition: 'padding linear 200ms',
                }}
                justifyContent="flex-start"
                alignItems="center"
              >
                {tab.icon && <Box mr="s-4">{tab.icon}</Box>}
                {tab.preTitle}
                {tab.title}
                {tab.quickSummary ? (
                  <>
                    <Text use="div" mx="5px" color="grey-20-opaque-90">
                      ·
                    </Text>
                    {tab.quickSummary}
                  </>
                ) : null}
              </Flex>
            </TabBarLink>
          )
        })}
      </TabBar>
    </Tooltip>
  )
}

export default TabBarNavigation
