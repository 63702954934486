import React, { useEffect, useState } from 'react'
import pluralize from 'pluralize'
import { connect } from 'lape'
import {
  Avatar,
  FilterButton,
  MoreBar,
  Subheader,
  TableWidget,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  documentsBulkRequestActivate,
  documentsBulkRequestFormRequests,
  documentsBulkRequestTriggerValidation,
  documentsBulkRequestUnassignEmployee,
  getDocumentsBulkRequestEligibleEmployeesTableRequests,
} from '@src/api/documents'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface,
  DocumentsBulkRequestInterface,
} from '@src/interfaces/documents'
import {
  documentsBulkRequestEligibleEmployeeNameColumn,
  documentsBulkRequestEligibleEmployeeCountryColumn,
  documentsBulkRequestEligibleEmployeeDepartmentColumn,
  documentsBulkRequestEligibleEmployeeSeniorityColumn,
  documentsBulkRequestEmployeeValidationStatusColumn,
} from '@src/constants/columns/documentsBulkRequestEligibleEmployees'
import Stat from '@components/Stat/Stat'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { navigateReplace } from '@src/actions/RouterActions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getRefreshRequestData, isReadyToSubmit } from '../common'

const getRow = (
  canEdit: boolean,
): RowInterface<DocumentsBulkRequestEligibleEmployeeInterface> => ({
  cells: [
    canEdit
      ? {
          ...getSelectCellConfig(),
        }
      : null,
    {
      ...documentsBulkRequestEligibleEmployeeNameColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeCountryColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeDepartmentColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeSeniorityColumn,
      width: 180,
    },
    {
      ...documentsBulkRequestEmployeeValidationStatusColumn,
      width: 200,
    },
  ].filter(Boolean),
})

type SelectedData =
  SelectTableWrapperOnChangeData<DocumentsBulkRequestEligibleEmployeeInterface>
type SelectedDataControls =
  SelectionControls<DocumentsBulkRequestEligibleEmployeeInterface>

const ReviewFormContent = connect(() => {
  const { values, reset } = useLapeContext<DocumentsBulkRequestInterface>()

  const [selectedData, setSelectedData] = useState<SelectedData>()
  const [selectedDataControls, setSelectedDataControls] = useState<SelectedDataControls>()

  const [isPendingEmployeesUnassign, setIsPendingEmployeesUnassign] = useState(false)
  const [showValidationTakesTimeBanner, setShowValidationTakesTimeBanner] =
    useState(false)

  const [enableEdit, setEnableEdit] = useState(false)

  const refreshRequestData = getRefreshRequestData(values.id, d => reset(d))

  const isValidating = ['validation_pending', 'validation_in_progress'].includes(
    values.status.id,
  )

  useEffect(() => {
    if (values.is_valid && !isValidating) {
      return undefined
    }
    let tmo: NodeJS.Timeout
    let int: NodeJS.Timer

    const cleanUp = () => {
      clearTimeout(tmo)
      clearInterval(int)
    }

    if (!isReadyToSubmit(values.status)) {
      // refresh status after 500ms delay before setting 5sec interval to make sure it's not a quick validation process
      tmo = setTimeout(() => {
        refreshRequestData().then(data => {
          if (!isReadyToSubmit(data.status)) {
            setShowValidationTakesTimeBanner(true)

            int = setInterval(() => {
              refreshRequestData().then(refetchedData => {
                if (isReadyToSubmit(refetchedData.status)) {
                  clearInterval(int)
                }
              })
            }, 3000)
          }
          if (data.is_valid === false) {
            setShowValidationTakesTimeBanner(false)
            cleanUp()
          }
        })
      }, 500)
    }
    return cleanUp
  }, [values.status.id])

  const table = useTable<
    DocumentsBulkRequestEligibleEmployeeInterface,
    DocumentsBulkRequestEligibleEmployeesStatsInterface
  >(
    getDocumentsBulkRequestEligibleEmployeesTableRequests(values.id),
    undefined,
    undefined,
    { disable: !values.id },
  )

  const filtersByWarnings =
    table.filterBy.find(filterBy => filterBy.columnName === 'is_valid')?.filters || []
  const isFilteredByWarnings = filtersByWarnings.some(filter => filter.id === 'false')

  const toggleFilterByWarnings = () => {
    table.onFilterChange(
      isFilteredByWarnings
        ? { columnName: 'is_valid', filters: [] }
        : { columnName: 'is_valid', filters: [{ id: 'false', name: 'false' }] },
    )
  }

  const canSubmit = isReadyToSubmit(values.status)

  return (
    <>
      <VStack space="s-16">
        <Subheader>
          <Subheader.Title>
            Review and confirm the employees that you are going to bulk request from
          </Subheader.Title>
        </Subheader>
        {!canSubmit && showValidationTakesTimeBanner && (
          <ActionWidget
            title="Eligible employees are being validated"
            text="Validation process can take a while.
            Please wait until it is finished to submit the request, or go to the preview page to submit later."
            avatar={
              <Avatar
                progress={0.6}
                useIcon="16/SandWatch"
                color={Token.color.warning}
                progressColor={Token.color.warning}
              />
            }
            avatarColor={Token.color.warning}
          >
            <MoreBar>
              <MoreBar.Action
                useIcon="ArrowThinRight"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW, {
                  id: values.id,
                })}
              >
                Go to preview
              </MoreBar.Action>
            </MoreBar>
          </ActionWidget>
        )}
        <TableWidget>
          <TableWidget.Info>
            <Stat val={table.stats?.total} label="Total" />
            <Stat
              val={table.stats?.validations_pending}
              color={Token.color.warning}
              label="Validating"
            />
            <Stat
              val={table.stats?.validations_failed}
              label={pluralize('Warning', table.stats?.validations_failed)}
              color={Token.color.warning}
            />
            <Stat
              val={table.stats?.validations_completed}
              label="OK"
              color={Token.color.success}
            />
          </TableWidget.Info>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action useIcon="Pencil" onClick={() => setEnableEdit(!enableEdit)}>
                Edit
              </MoreBar.Action>
              {enableEdit && (
                <MoreBar.Action
                  disabled={!selectedData?.someSelected}
                  useIcon="CrossSmall"
                  variant="negative"
                  pending={isPendingEmployeesUnassign}
                  onClick={async () => {
                    if (selectedData?.selectedRowsIds) {
                      try {
                        setIsPendingEmployeesUnassign(true)
                        await documentsBulkRequestUnassignEmployee(
                          values.id,
                          Array.from(selectedData.selectedRowsIds).map(id => Number(id)),
                        )
                        documentsBulkRequestTriggerValidation(values.id)
                        values.status.id = 'validation_in_progress'
                      } finally {
                        setIsPendingEmployeesUnassign(false)
                        table.refresh()
                        selectedDataControls?.resetState()
                      }
                    }
                  }}
                >
                  Remove selected
                </MoreBar.Action>
              )}
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Filters>
            {!!table.stats?.validations_failed && (
              <FilterButton
                active={isFilteredByWarnings}
                onClick={toggleFilterByWarnings}
                pending={table.loading}
                disabled={!table.stats?.validations_failed}
              >
                Show warnings
              </FilterButton>
            )}
          </TableWidget.Filters>
          <TableWidget.Table>
            <SelectTableWrapper
              enabled={enableEdit}
              onChange={setSelectedData}
              filters={table.filterBy}
              tableDataLength={table.data.length}
              onControlsLoaded={controls => setSelectedDataControls(controls)}
            >
              <AdjustableTable<
                DocumentsBulkRequestEligibleEmployeeInterface,
                DocumentsBulkRequestEligibleEmployeesStatsInterface
              >
                useWindowScroll
                idPath="employee.id"
                name={TableNames.BulkRequestDocumentsEligibleEmployees}
                row={getRow(enableEdit)}
                {...table}
                noDataMessage="All eligible employees will appear here"
                dataType="Eligible employee"
              />
            </SelectTableWrapper>
          </TableWidget.Table>
        </TableWidget>
      </VStack>
      <PageActions>
        <NewSaveButtonWithPopup
          mt="s-64"
          disabled={!canSubmit}
          hideWhenNoChanges={false}
          successText="Your request was successfully submitted"
          onClick={() => {
            return documentsBulkRequestActivate(values.id)
          }}
          onAfterSubmit={() =>
            navigateReplace(
              pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW, { id: values.id }),
            )
          }
        >
          Submit request
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})

export const Review = () => (
  <PageBody>
    <Form api={documentsBulkRequestFormRequests}>
      <ReviewFormContent />
    </Form>
  </PageBody>
)
