import {
  Flex,
  Icon,
  TableWidget,
  TextButton,
  Token,
  Text,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { useGetGoal } from '@src/api/goals'
import { kpisRequests } from '@src/api/kpis'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import React, { useState } from 'react'
import { useConfirmationDialog } from '../../../common/utils'

export const TargetsList = ({
  onSelected,
  viewMode,
}: {
  onSelected?: (kpi: KpiInterface, index: number) => void
  viewMode: boolean
}) => {
  const { values, errors } = useLapeContext<GoalsInterface>()
  const [pendingDeleteId, setPendingdeleteId] = useState<number>()
  const statusPopup = useStatusPopup()
  const { confirmationDialog, confirm } = useConfirmationDialog({
    variant: 'compact',
  })

  const showError = (title: string) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
      </StatusPopup>,
    )

  // cascaded kpi couldbe only the one
  const cascadedTarget =
    values.kpis?.length && values.kpis[0].update_type === UpdateTypes.cascaded
      ? values.kpis[0]
      : false

  const hasErrors = !!errors.kpis

  // need to fetch goal because goals list endpoint doesn't return review cycles for targets
  const { data: parentGoal } = useGetGoal(values.parent?.id)

  const getKPIsProps = () => {
    return cascadedTarget
      ? {
          data: parentGoal?.kpis || [],
          count: values.parent?.kpis.length || 0,
          noDataMessage: values.parent?.kpis
            ? undefined
            : 'Select parent goal to see cascaded targets',
          hiddenCells: { actions_column: true },
        }
      : {
          data: values.kpis,
          count: values.kpis.length,
        }
  }

  const cells: ColumnCellInterface<KpiInterface>[] = viewMode
    ? [
        {
          type: CellTypes.insert,
          idPoint: 'review_cycle.cycle_id',
          dataPoint: 'review_cycle',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          insert: ({ data }) => {
            // only one target can be inside goal kpi
            const target =
              (data.targets && data.targets[0]) ||
              (data.target_epics && data.target_epics[0])

            if (target?.employee_cycle) {
              return target.employee_cycle.name
            }

            return target?.review_cycle?.name || ' - '
          },
          title: 'Cycle',
          width: 100,
        },
        {
          ...kpiUpdateTypeColumn,
          title: 'Type',
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...kpiGenericNameColumn,
          title: 'Name',
          sortKey: null,
          filterKey: null,
          width: 300,
        },
        {
          ...kpiPerformanceColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          ...kpiInitialValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiCurrentValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiTargetColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiUnitColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
      ]
    : [
        {
          type: CellTypes.insert,
          idPoint: 'review_cycle.cycle_id',
          dataPoint: 'review_cycle',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          insert: ({ data }) => {
            // only one target can be inside goal kpi
            const target =
              (data.targets && data.targets[0]) ||
              (data.target_epics && data.target_epics[0])

            if (target?.employee_cycle) {
              return target.employee_cycle.name
            }

            return target?.review_cycle?.name || ' - '
          },
          title: 'Cycle',
          width: 100,
        },
        {
          ...kpiUpdateTypeColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          ...kpiGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 300,
        },
        {
          ...kpiInitialValueColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiTargetColumn,
          sortKey: null,
          filterKey: null,
          textAlign: 'left',
          width: 80,
        },
        {
          ...kpiUnitColumn,
          sortKey: null,
          filterKey: null,
          width: 100,
        },
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }) => {
            return data.update_type === UpdateTypes.cascaded ? null : (
              <Flex gap="s-8" justifyContent="flex-end">
                {onSelected && (
                  <TextButton onClick={() => onSelected(data, values.kpis.indexOf(data))}>
                    <Icon name="Pencil" size={16} color={Token.color.greyTone50} />
                  </TextButton>
                )}
                <TextButton
                  disabled={pendingDeleteId !== undefined}
                  onClick={async () => {
                    try {
                      const confirmed = await confirm({
                        body: `Are you sure you want to delete ${data.name} target`,
                        yesBtnVariant: 'negative',
                        yesMessage: 'Delete',
                      })

                      if (confirmed) {
                        setPendingdeleteId(data.id)
                        await kpisRequests.deleteItem(data.id)
                        values.kpis = values.kpis.filter(kpi => kpi !== data)
                      }
                    } catch (err) {
                      captureException(err)
                      showError('Failed to delete target')
                    } finally {
                      setPendingdeleteId(undefined)
                    }
                  }}
                >
                  <Icon
                    name={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
                    size={16}
                    color={Token.color.greyTone50}
                  />
                </TextButton>
              </Flex>
            )
          },
          title: '',
          width: 80,
        },
      ]

  return values.kpis?.length ? (
    <TableWidget style={{ padding: 0 }}>
      {hasErrors ? (
        <TableWidget.Status>
          <Text variant="caption" color={Token.color.error}>
            Some of the targets are invalid
          </Text>
        </TableWidget.Status>
      ) : null}
      <TableWidget.Table>
        <AdjustableTable<KpiInterface & { actions_column?: never }>
          hideCountAndButtonSection={viewMode}
          name={TableNames.KPIs}
          dataType="Target"
          row={{
            highlight: () => (errors.kpis ? Token.color.inputError : ''),
            cells,
          }}
          {...getKPIsProps()}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  ) : null
}
