import React, { useCallback } from 'react'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'
import { KpiInterface } from '@src/interfaces/kpis'
import { useRef, useState } from 'react'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'

export const getTargets = (kpi: KpiInterface) => {
  return kpi.targets || kpi.target_epics
}

export const useConfirmationDialog = (
  props: Partial<ConfirmationDialogInterface> = {},
) => {
  const [popupProps, setPopupProps] = useState<
    { open: boolean } & Partial<ConfirmationDialogInterface>
  >({
    open: false,
    ...props,
  })
  const resolverRef = useRef<(result: boolean) => void>()

  const onCancel = () => {
    if (resolverRef.current) {
      resolverRef.current(false)
      setPopupProps({ ...props, open: false })
    }
  }

  const onConfirm = () => {
    if (resolverRef.current) {
      resolverRef.current(true)
      setPopupProps({ ...props, open: false })
    }
  }

  const popup = (
    <ConfirmationDialog
      {...popupProps}
      onClose={onCancel}
      onConfirm={onConfirm}
      onExited={onCancel}
      onReject={onCancel}
    />
  )

  return {
    confirmationDialog: popup,
    confirm: (override: Partial<ConfirmationDialogInterface> = {}) => {
      return new Promise<boolean>(resolve => {
        resolverRef.current = resolve
        setPopupProps({
          ...popupProps,
          ...override,
          open: true,
        })
      })
    },
  }
}

export const useSubmitFlowHelpers = ({
  confirmProps,
}: {
  confirmProps?: Partial<ConfirmationDialogInterface>
} = {}) => {
  const statusPopup = useStatusPopup()

  const showError = useCallback((title: string, description?: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {!!description && (
          <StatusPopup.Description>{description}</StatusPopup.Description>
        )}
      </StatusPopup>,
    )
  }, [])

  return { ...useConfirmationDialog(confirmProps), showError }
}
