import React, { useState } from 'react'
import { AxiosError } from 'axios'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import { Avatar } from '@revolut/ui-kit'

import {
  DocumentsTemplateFieldInterface,
  DocumentsTemplateDataFieldRecipientId,
  DocumentsTemplateDataFieldSourceId,
  DocumentsTemplateCommonFieldInterface,
  ESignatureTemplateInterface,
} from '@src/interfaces/documentsTemplates'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'

export const fieldTypes = ['text', 'number', 'date', 'money', 'signature'] as const
export type FieldType = typeof fieldTypes[number]

export type IndexedFieldKey = string

export interface BaseFieldInterface {
  id: IndexedFieldKey
  data: DocumentsTemplateFieldInterface
}

export interface FieldActionsInterface {
  isSelected: boolean
  setSelected: () => void
  setUnselected: () => void
  onDelete?: () => void
  onRename?: () => void
}

interface FieldErrorsInterface {
  errors: Record<string, string[]> | undefined
  clearError?: (indexedKey: IndexedFieldKey, errorField: string) => void
}

export type TemplateField = BaseFieldInterface &
  FieldActionsInterface &
  FieldErrorsInterface

export const mapTypeToAvatar: Record<FieldType, React.ReactNode> = {
  text: <Avatar useIcon="Text" />,
  number: <Avatar size={40} label="1" />,
  date: <Avatar useIcon="CalendarDate" />,
  money: <Avatar useIcon="Coins" />,
  signature: <Avatar useIcon="Pencil" />,
}

export const getSourceIdToLabel = (
  type?: FieldType,
  verbose: boolean = true,
): Record<DocumentsTemplateDataFieldSourceId, string> => {
  const idToLabel = {
    custom_value: verbose ? 'Filled with a custom value' : 'Custom value',
    sql_source: 'Filled from Revolut People data',
    to_be_filled: 'To be filled by recipient',
  }

  switch (type) {
    case 'text':
      return {
        ...idToLabel,
        custom_value: verbose ? 'Filled with a custom text' : 'Custom text',
      }
    case 'number':
      return {
        ...idToLabel,
        custom_value: verbose ? 'Filled with a custom number' : 'Custom number',
      }
    case 'date':
      return {
        ...idToLabel,
        custom_value: verbose ? 'Filled with a custom date' : 'Custom date',
      }
    case 'money':
      return {
        ...idToLabel,
        custom_value: verbose ? 'Filled with a custom amount' : 'Custom amount',
      }
    default:
      return idToLabel
  }
}

const recipientIdToLabel: Record<DocumentsTemplateDataFieldRecipientId, string> = {
  recipient: 'Employee',
  issuer: 'HR Manager',
}

const getOptionsFromIdToLabel = <T extends string>(
  obj: Record<T, string>,
): RadioSelectOption<IdAndName<T>>[] =>
  Object.entries(obj).map(([id, name]) => {
    const value = { id, name } as IdAndName<T>
    return { id, label: name, value }
  })

export const getSourceOptions = (type?: FieldType) =>
  getOptionsFromIdToLabel<DocumentsTemplateDataFieldSourceId>(getSourceIdToLabel(type))
export const recipientOptions =
  getOptionsFromIdToLabel<DocumentsTemplateDataFieldRecipientId>(recipientIdToLabel)

const defaultPosition = {
  width: 140,
  height: 18,
  x_position: 50,
  y_position: 50,
}

const defaultSignerRole = {
  signer_role: { id: 'recipient' as const, name: recipientIdToLabel.recipient },
}

export const getFieldName = (
  data: DocumentsTemplateCommonFieldInterface | undefined,
  type: FieldType,
  idx: number,
) => data?.name || `${capitalize(type)} field ${idx + 1}`

const defaultSettings = {
  ...defaultPosition,
  ...defaultSignerRole,
  placeholder: '',
  sql_source: null,
  custom_value: null,
}

export const addNewField = (
  values: ESignatureTemplateInterface,
  fieldType: FieldType,
  pageNum: number,
) => {
  switch (fieldType) {
    case 'text': {
      values.text_fields = [
        ...values.text_fields,
        {
          name: getFieldName(undefined, 'text', values.text_fields.length),
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('text').custom_value,
          },
          ...defaultSettings,
        },
      ]
      break
    }
    case 'number': {
      values.number_fields = [
        ...values.number_fields,
        {
          name: getFieldName(undefined, 'number', values.number_fields.length),
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('number').custom_value,
          },
          ...defaultSettings,
        },
      ]
      break
    }
    case 'date': {
      values.date_fields = [
        ...values.date_fields,
        {
          name: getFieldName(undefined, 'date', values.date_fields.length),
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('date').custom_value,
          },
          ...defaultSettings,
        },
      ]
      break
    }
    case 'money': {
      values.money_fields = [
        ...values.money_fields,
        {
          name: getFieldName(undefined, 'money', values.money_fields.length),
          page_number: pageNum,
          source_type: {
            id: 'custom_value',
            name: getSourceIdToLabel('money').custom_value,
          },
          currency: null,
          ...defaultSettings,
        },
      ]
      break
    }
    case 'signature': {
      values.signature_fields = [
        ...values.signature_fields,
        {
          name: getFieldName(undefined, 'signature', values.signature_fields.length),
          page_number: pageNum,
          source_type: {
            id: 'to_be_filled',
            name: getSourceIdToLabel('signature').to_be_filled,
          },
          ...omit(defaultSettings, 'custom_value'),
        },
      ]
      break
    }
    default:
      break
  }
}

export const getIndexedFieldKey = (
  fieldType: FieldType,
  pageIdx: number | undefined,
  idx: number,
): IndexedFieldKey => `${fieldType}_${idx}_${pageIdx || 0}`

export const getFieldInfoFromIndexedKey = (key: IndexedFieldKey) => {
  const [type, idx, pageIdx] = key.split('_')

  return { type: type as FieldType, pageNum: Number(pageIdx) + 1, idx: Number(idx) }
}

type FieldKey =
  | 'text_fields'
  | 'number_fields'
  | 'date_fields'
  | 'money_fields'
  | 'signature_fields'

const getFieldKeyByType = (type: FieldType): FieldKey => `${type}_fields` as FieldKey

export const removeField = (
  key: IndexedFieldKey,
  values: ESignatureTemplateInterface,
) => {
  const { type, idx } = getFieldInfoFromIndexedKey(key)
  const fieldKey = getFieldKeyByType(type)

  if (!values[fieldKey]?.length) {
    return
  }
  values[fieldKey].splice(idx, 1)
}

export const getFieldsByPage = (
  fieldType: FieldType,
  pageNum: number | undefined,
  values: ESignatureTemplateInterface,
): DocumentsTemplateFieldInterface[] => {
  const fields = values[getFieldKeyByType(fieldType)]

  if (!Array.isArray(fields)) {
    return []
  }
  if (pageNum === undefined) {
    return fields as DocumentsTemplateFieldInterface[]
  }
  return fields.filter(
    f => f.page_number === pageNum,
  ) as DocumentsTemplateFieldInterface[]
}

export const getFieldDataByIndexedKey = (
  key: IndexedFieldKey,
  values: ESignatureTemplateInterface,
) => {
  const { type, idx, pageNum } = getFieldInfoFromIndexedKey(key)

  return getFieldsByPage(type, pageNum, values)?.[idx]
}

export const hasFieldsOnPage = (
  pageNum: number | undefined,
  values: ESignatureTemplateInterface,
) =>
  fieldTypes
    .map(fieldType => getFieldsByPage(fieldType, pageNum, values).length)
    .some(Boolean)

export const hasAnyFields = (values: ESignatureTemplateInterface) =>
  hasFieldsOnPage(undefined, values)

export type FieldSettingKey = keyof DocumentsTemplateFieldInterface | 'non_field_error'
export type ErrorsByFieldSettings = Record<FieldSettingKey, string[]>
export type ErrorsByFieldsKeys = Record<IndexedFieldKey, Partial<ErrorsByFieldSettings>>

type ValidationErrors = Record<FieldKey, ErrorsByFieldSettings[]>

const mapFieldsValidationErrorsToIndexedKeys = (
  errors: ValidationErrors | undefined,
  values: ESignatureTemplateInterface,
) => {
  if (!errors) {
    return {}
  }
  return Object.entries(errors).reduce<ErrorsByFieldsKeys>(
    (acc, [field, errorsByField]) => {
      if (!(field in values)) {
        return acc
      }
      errorsByField.forEach((errorByField, idx) => {
        if (isEmpty(errorByField)) {
          return
        }
        const fieldKey = field as FieldKey
        const fieldData = values[fieldKey][idx]
        const indexedFieldKey = getIndexedFieldKey(
          fieldKey.split('_')[0] as FieldType,
          fieldData.page_number - 1,
          idx,
        )
        acc[indexedFieldKey] = errorByField
      })
      return acc
    },
    {},
  )
}

export type UseValidationErrorsReturnType = {
  data: ErrorsByFieldsKeys
  parse: (err: AxiosError) => void
  resetField: (indexedKey: IndexedFieldKey, errorField: FieldSettingKey) => void
  reset: () => void
}
export const useValidationErrors = (
  values: ESignatureTemplateInterface,
): UseValidationErrorsReturnType => {
  const [errorsByFieldsKeys, setErrorsByFieldsKeys] = useState<ErrorsByFieldsKeys>({})

  return {
    data: errorsByFieldsKeys,
    parse: err =>
      setErrorsByFieldsKeys(
        mapFieldsValidationErrorsToIndexedKeys(err.response?.data, values),
      ),
    resetField: (indexedKey, errorField) =>
      setErrorsByFieldsKeys({
        ...errorsByFieldsKeys,
        [indexedKey]: omit(errorsByFieldsKeys[indexedKey], [
          errorField,
          'non_field_errors',
        ]),
      }),
    reset: () => setErrorsByFieldsKeys({}),
  }
}
