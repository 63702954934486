import { selectorKeys } from '@src/constants/api'
import KPIWarning from '@components/ColumnInserts/KPIWarning/KPIWarning'
import React, { useEffect } from 'react'
import { KpiInterface } from '@src/interfaces/kpis'
import { connect } from 'lape'
import { Flex, Box } from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownAction from '@components/Inputs/DropdownAction/DropdownAction'

export enum QueryDB {
  Helios = 'helios',
  PeopleOps = 'people_ops',
}

type QueryDBType = KpiInterface['sql_query_db']

interface Props extends Omit<RadioSelectInputProps<QueryDBType>, 'options'> {
  data?: KpiInterface
  hideLookerOption?: boolean
}

const KPIDataBaseSelection = ({ data, hideLookerOption, ...props }: Props) => {
  const { options } = useFetchOptions<QueryDBType>(selectorKeys.kpi_sql_query_databases)

  useEffect(() => {
    if (!!options.length && props.value === undefined && !!props.onChange) {
      props.onChange(options[0].value)
    }
  }, [options])

  return (
    <Flex>
      <Box flex="1 0 auto">
        <RadioSelectInput
          options={options.filter(
            option => !(hideLookerOption && option.value.id === 'looker'),
          )}
          renderInput={(open, setOpen, ref) => (
            <DropdownAction open={open} onClick={() => setOpen(!open)} ref={ref}>
              {props.label || 'Database'}
            </DropdownAction>
          )}
          {...props}
        />
      </Box>
      {data && <KPIWarning data={data} />}
    </Flex>
  )
}

export default connect(KPIDataBaseSelection)
