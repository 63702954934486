import { goalsRequests } from '@src/api/goals'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { GoalFormPage } from './GoalFormPage'
import { GoalPreviewPage } from './Form/Preview/GoalPreviewPage'
import { SidebarProvider } from './common/SidebarProvider'
import { EntityFetcher } from './EntityFetcher'

export const GoalRouterWithForm = () => {
  return (
    <Form
      api={goalsRequests}
      fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
      refetchOnLocationChange
    >
      <EntityFetcher>
        <SidebarProvider>
          <Switch>
            <Route exact path={ROUTES.FORMS.GOAL.NEW}>
              <GoalFormPage />
            </Route>

            <Route exact path={ROUTES.FORMS.GOAL.PREVIEW}>
              <GoalPreviewPage />
            </Route>

            <Route exact path={ROUTES.FORMS.GOAL.EDIT}>
              <GoalFormPage />
            </Route>
          </Switch>
        </SidebarProvider>
      </EntityFetcher>
    </Form>
  )
}
