import { Box, Cell, ErrorWidget, VStack } from '@revolut/ui-kit'
import { getAllLookerDashboards } from '@src/api/analyticsDashboards'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import React, { useEffect, useState } from 'react'
import { KeyMetricsPreview } from '@src/pages/Forms/GoalForm/common/KeyMetricsPreview'
import { SideAction } from './SidebarAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const AddDashboardForm = ({
  initialDashboard = null,
  onSave,
  pending,
}: {
  initialDashboard?: GenericAnalyticsDashboardInterface | null
  onSave: (dashboard: GenericAnalyticsDashboardInterface) => void
  pending: boolean
}) => {
  const [dashboard, setDashboard] = useState<GenericAnalyticsDashboardInterface | null>(
    initialDashboard,
  )

  useEffect(() => {
    setDashboard(initialDashboard)
  }, [initialDashboard])

  return (
    <>
      <VStack space="s-16">
        {initialDashboard ? undefined : (
          <RadioSelectInput<GenericAnalyticsDashboardInterface>
            label="Select dashboard"
            value={dashboard}
            onChange={setDashboard}
            selector={() =>
              getAllLookerDashboards().then(response => response.data.results)
            }
          />
        )}
        {dashboard ? (
          <Cell p="s-12">
            <Box width="100%">
              <FormPreview<GenericAnalyticsDashboardInterface> data={dashboard}>
                <FormPreview.Item
                  title="Dashboard name"
                  field="name"
                  to={() =>
                    pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, {
                      id: dashboard.id,
                    })
                  }
                />
                <FormPreview.Details title="Description" field="description" />
                <FormPreview.Item
                  insert={() => <KeyMetricsPreview dashboard={dashboard} />}
                  title="Key-metric"
                />
                <FormPreview.Item
                  type="capitalized"
                  field="dashboard_type"
                  title="Type"
                />
                <FormPreview.Item
                  type="link"
                  field="embed_url"
                  title="Link to external"
                />
                <FormPreview.Item
                  type="date"
                  field="creation_date_time"
                  title="Created on"
                />
                <FormPreview.Item type="status" field="status" title="Status" />

                <FormPreview.Item type="employee" field="owner" title="Owner" />
              </FormPreview>
            </Box>
          </Cell>
        ) : (
          <ErrorWidget>
            <ErrorWidget.Description>
              Select an internal dashboard
            </ErrorWidget.Description>
          </ErrorWidget>
        )}
      </VStack>
      {!initialDashboard && (
        <SideAction
          pending={pending}
          disabled={!dashboard}
          onClick={() => onSave(dashboard!)}
        >
          Add dashboard
        </SideAction>
      )}
    </>
  )
}
