import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import React from 'react'
import { RoadmapsList } from './RoadmapsList'
import { ActionButton } from '@revolut/ui-kit'

export const RoadmapsWidget = ({
  viewMode = false,
  onAdded,
}: {
  viewMode?: boolean
  onAdded?: () => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  return (
    <BaseWidget
      title={viewMode ? 'Roadmap' : 'Link your roadmaps'}
      desciption={
        viewMode
          ? undefined
          : '(Optional) What projects, tasks and actions are planned to deliver the goal?'
      }
      icon="Map"
      emptyState={{
        description: 'No roadmaps added',
        icon: 'Map',
      }}
      action={
        onAdded ? (
          <ActionButton useIcon="Plus" onClick={onAdded}>
            Add roadmap
          </ActionButton>
        ) : undefined
      }
    >
      {values.roadmaps && values.roadmaps.length ? (
        <RoadmapsList viewMode={viewMode} />
      ) : null}
    </BaseWidget>
  )
}
