import { Ellipsis, TagBar } from '@revolut/ui-kit'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import React from 'react'

export const KeyMetricsPreview = ({
  dashboard,
}: {
  dashboard: GenericAnalyticsDashboardInterface
}) => {
  const tags = dashboard.related_goals

  return tags && tags.length ? (
    <TagBar>
      {tags.map(item => (
        <TagBar.Item key={item.id} color="indigo" style={{ maxWidth: '150px' }}>
          <Ellipsis>{item.name}</Ellipsis>
        </TagBar.Item>
      ))}
    </TagBar>
  ) : null
}
