import { HStack, VStack, Text, Box, Link, Icon, Button, Bar } from '@revolut/ui-kit'
import { testSqlQuery } from '@src/api/kpis'
import LapeNewCodeEditor, {
  LapeCodeEditorProps,
} from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { KPI_SQL_QUERY_URL } from '@src/constants/externalLinks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import KPIDataBaseSelection, {
  QueryDB,
} from '@src/features/FormTabs/Kpi/KPIDataBaseSelection'
import SqlQueryTesting from '@src/features/FormTabs/Kpi/SqlQueryTesting'
import { KpiInterface, RunQueryResponse, UpdateTypes } from '@src/interfaces/kpis'
import { connect } from 'lape'
import React, { useEffect, useState } from 'react'

interface Props {
  previousQuery?: KpiInterface['sql_query']
  hideLooker?: boolean
  codeEditorProps?: Partial<LapeCodeEditorProps>
}

export const QueryField = connect(
  ({ previousQuery, hideLooker = false, codeEditorProps = {} }: Props) => {
    const { values } = useLapeContext<KpiInterface>()
    const [pending, setPending] = useState(false)
    const [result, setResult] = useState<RunQueryResponse | null>()

    const responseTime = result
      ? result.query_time_seconds
      : values.extra?.query_time_seconds

    const isLooker =
      values.sql_query_db?.id === 'looker' || values.update_type === UpdateTypes.looker
    const allowedToRun = isLooker ? !!values.look_url : !!values.sql_query
    const tooltipText = allowedToRun
      ? `Click here to check whether your ${isLooker ? 'URL' : 'query'} works`
      : `${isLooker ? 'Look URL' : 'SQL Query'} is required`
    const runButtonLabel = isLooker ? 'Fetch data' : 'Run query'

    const responseTimeText = responseTime ? (
      <Text use="p">
        Query took <b>{responseTime?.toFixed(2)}</b> seconds to run.
      </Text>
    ) : null

    useEffect(() => {
      if (values.update_type === UpdateTypes.looker) {
        values.sql_query_db = { id: 'looker', name: 'Looker' }
      }
    }, [])

    const handleRun = async () => {
      setPending(true)
      setResult(null)

      try {
        const response = await testSqlQuery(
          isLooker ? undefined : values.sql_query,
          values.id,
          values.sql_query_db?.id,
          isLooker ? values.look_url : undefined,
        )
        setResult(response.data)

        return response
      } catch (err) {
        const errorResponse = err.response
        const errors = err.response?.data
        if (
          !errorResponse ||
          errorResponse.status === 502 ||
          !errors ||
          typeof errors === 'string'
        ) {
          setResult({ error: 'Query timed out' })
        } else {
          const error: string = Object.entries(errors as Record<string, string[]>).reduce(
            (e, [field, messages]) => {
              return Array.isArray(messages)
                ? `${e}${field}: ${messages.join(' ')};`
                : `${e}${field}: ${messages};`
            },
            '',
          )
          setResult({ error })
        }
      } finally {
        setPending(false)
      }

      return null
    }

    return (
      <>
        <VStack space="s-8" mt="s-24">
          {values.update_type !== UpdateTypes.looker && (
            <HStack align="center">
              <Text variant="caption" pr="s-8">
                Data connection:{' '}
              </Text>
              <KPIDataBaseSelection
                hideLookerOption={hideLooker}
                data={values}
                onChange={option => {
                  if (option) {
                    setResult(undefined)
                    values.sql_query_db = option
                  }
                }}
                value={values.sql_query_db}
                label={values.sql_query_db?.name}
              />
            </HStack>
          )}
          {isLooker ? (
            <LapeNewInput
              name="look_url"
              label="Look URL"
              required
              message={
                responseTimeText || 'Provide the URL for the Look you wish to connect to'
              }
            />
          ) : (
            <LapeNewCodeEditor
              name="sql_query"
              placeholder="SQL Query"
              bottomInfo={
                <Box pt="s-8" px="s-12">
                  <Text use="p">
                    <Text use="p">
                      The query runs every night and updates the current value.
                    </Text>
                    {values.sql_query_db?.id === QueryDB.Helios && (
                      <Text use="p">
                        Please verify that your query complies with{' '}
                        <Link target="_blank" href={KPI_SQL_QUERY_URL}>
                          Helios Rules
                        </Link>{' '}
                        or the query will not run
                      </Text>
                    )}
                  </Text>
                  {responseTimeText}
                </Box>
              }
              diff={previousQuery || undefined}
              {...codeEditorProps}
            />
          )}
        </VStack>
        <Bar>
          <Tooltip text={tooltipText} placement="right">
            <Button
              variant="secondary"
              size="sm"
              onClick={handleRun}
              disabled={pending || !allowedToRun}
              useIcon={pending ? undefined : <Icon name="Play" />}
              pending={pending}
            >
              {pending ? '' : runButtonLabel}
            </Button>
          </Tooltip>
        </Bar>
        {result && <SqlQueryTesting response={result} />}
      </>
    )
  },
)
