import {
  Cell,
  HStack,
  Icon,
  TabBar,
  Text,
  TextSkeleton,
  Token,
  VStack,
  chain,
  createChain,
} from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalsTimelineEventInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React, { useMemo, useState } from 'react'
import { ParentBadge } from './ParentBadge'
import { goalsTimelineTableRequests } from '@src/api/goals'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@src/components/ButtonFilters/ButtonFilters'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatDateTime } from '@src/utils/format'

const SectionTitile = ({ title }: { title: string }) => {
  return (
    <Text use="h6" color={Token.color.greyTone50}>
      {title}
    </Text>
  )
}

const GoalTimelineEvent = ({ event }: { event: GoalsTimelineEventInterface }) => {
  const title = chain(
    <UserWithAvatar {...event.employee} />,
    <Text color={Token.color.greyTone50}>
      {formatDateTime(event.creation_date_time)}
    </Text>,
  )

  return (
    <Cell>
      <VStack space="s-8">
        <HStack align="center">{title}</HStack>

        <HStack align="center" space="s-8">
          <Icon size={16} name="InfoOutline" color={Token.color.greyTone50} />
          <Text variant="caption" color={Token.color.greyTone50}>
            {event.title}
          </Text>
        </HStack>
        <Text>{event.summary}</Text>
      </VStack>
    </Cell>
  )
}

const GoalTimeline = ({
  timelineTable,
}: {
  timelineTable: useTableReturnType<GoalsTimelineEventInterface>
}) => {
  const filtersConfig: ButtonFilterConfig = useMemo(
    () => ({
      creation_date_time: {
        title: 'Date',
        selector: selectorKeys.none,
        useIcon: 'Calendar',
        type: 'DateRange',
      },
      employee: {
        title: 'Employee',
        selector: selectorKeys.employee,
        useIcon: 'Profile',
        type: 'MultiSelect',
      },
      category: {
        title: 'Category',
        selector: selectorKeys.goal_timeline_categories,
        type: 'MultiSelect',
      },
    }),
    [],
  )
  return (
    <VStack space="s-16">
      <ButtonFilters
        filtersConfig={filtersConfig}
        onChange={timelineTable.onFilterChange}
        filters={timelineTable.filterBy}
      />
      <VStack space="s-12">
        {timelineTable.data.map(event => (
          <GoalTimelineEvent event={event} key={event.id} />
        ))}
      </VStack>
    </VStack>
  )
}

export const GoalsPreviewRightSide = ({ cycle }: { cycle: ReviewCyclesInterface }) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [tab, setTab] = useState<'details' | 'log' | undefined>('details')
  const timelineTable = useTable(goalsTimelineTableRequests(values.id), [], [], {
    disableQuery: true,
  })
  const parent = values.parent

  const newLineChain = createChain(<br />)

  return (
    <VStack space="s-24">
      <TabBar variant="segmented" value={tab} onChange={setTab}>
        <TabBar.Item to="details">Details</TabBar.Item>
        <TabBar.Item disabled={timelineTable.loading} to="log">
          {chain(
            'Update log',
            timelineTable.loading ? <TextSkeleton width={20} /> : timelineTable.count,
          )}
        </TabBar.Item>
      </TabBar>
      {tab === 'details' ? (
        <>
          {parent ? (
            <VStack space="s-12">
              <SectionTitile title="Parent goal" />
              <ParentBadge id={parent.id} name={parent.name} cycle={cycle} />
            </VStack>
          ) : null}
          <VStack space="s-12">
            <SectionTitile title="Description" />
            <Text>{newLineChain(values.description.split('\n'))}</Text>
          </VStack>
        </>
      ) : (
        <GoalTimeline timelineTable={timelineTable} />
      )}
    </VStack>
  )
}
