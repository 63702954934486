import { useLapeContext } from '@src/features/Form/LapeForm'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { GoalsInterface } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import { DashboardsList } from './DashboardsList'
import React from 'react'
import { ActionButton } from '@revolut/ui-kit'

export const DashboardsWidget = ({
  onAdd,
  onSelected,
  viewMode = false,
}: {
  onAdd?: () => void
  onSelected: (dashboard: GenericAnalyticsDashboardInterface) => void
  viewMode?: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  return (
    <BaseWidget
      title="Link supporting analytics"
      desciption="(Optional) Are there performance analytics which help to track this goal?"
      icon="ViewGrid"
      emptyState={{
        description: 'No dashboards or reports added',
        icon: 'ViewGrid',
      }}
      action={
        onAdd ? (
          <ActionButton onClick={onAdd} useIcon="16/Plus">
            Add dashboard
          </ActionButton>
        ) : undefined
      }
    >
      {values.dashboards?.length ? (
        <DashboardsList viewMode={viewMode} onViewRequested={onSelected} />
      ) : null}
    </BaseWidget>
  )
}
