import React from 'react'
import { useGetActiveOnlineTests } from '@src/api/hiringProcess'
import upperFirst from 'lodash/upperFirst'
import DropdownAction from '@components/Inputs/DropdownAction/DropdownAction'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { FormError } from '@src/features/Form/LapeForm'
import { OnlineTestInterface } from '@src/interfaces/hiringProccess'

type Props = {
  testPlatform?: string
  noInput?: boolean
  onChange: (onlineTest: IdAndName | null) => void
  value?: IdAndName
  error?: FormError<OnlineTestInterface>
}

const OnlineTestRadioSelect = ({
  testPlatform,
  noInput,
  onChange,
  value,
  error,
}: Props) => {
  const { data: activeOnlineTests, isLoading: activeOnlineTestLoading } =
    useGetActiveOnlineTests(testPlatform)

  let message

  if (testPlatform) {
    message = `The list is populated from ${upperFirst(
      testPlatform,
    )} and is refreshed every up to 3 hours`
  }

  if (error) {
    message = error
  }

  return (
    <RadioSelectInput<IdAndName>
      label="Test name"
      hasError={!!error}
      inputProps={{
        'data-name': 'online_test',
      }}
      options={
        activeOnlineTests?.map(item => ({
          label: item.name,
          value: item,
        })) || []
      }
      message={message}
      loading={activeOnlineTestLoading}
      fitInAnchor={false}
      renderInput={
        noInput
          ? (open, setOpen, anchorRef) => (
              <DropdownAction open={open} ref={anchorRef} onClick={() => setOpen(!open)}>
                Change
              </DropdownAction>
            )
          : undefined
      }
      onChange={onlineTest => onChange(onlineTest)}
      value={value}
    />
  )
}

export default OnlineTestRadioSelect
