import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'lape'
import { Box, Subheader, Text, Token, VStack } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { documentTemplatesFormRequest } from '@src/api/documentsTemplates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ESignatureTemplateEditor } from '@src/features/ESignatureTemplateEditor'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { useValidationErrors } from '@src/features/ESignatureTemplateEditor/common'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'

const TemplatesEditorFormContent = connect(
  ({ nextTabButton }: { nextTabButton: React.ReactNode }) => {
    const { values, submit } = useLapeContext<DocumentsTemplatesInterface>()
    const errors = useValidationErrors(values)

    return (
      <VStack mt="s-8" space="s-24">
        <Box>
          <Subheader>
            <Subheader.Title>
              <FormattedMessage
                id="documents.bulkrequest.templates.editor.title"
                defaultMessage="Review your templates"
              />
            </Subheader.Title>
          </Subheader>
          <Text variant="caption" color={Token.color.greyTone50}>
            <FormattedMessage
              id="documents.bulkrequest.templates.editor.subtitle"
              defaultMessage="Any changes you save here will be applied to the original template as well"
            />
          </Text>
        </Box>
        <ESignatureTemplateEditor values={values} errors={errors} />
        <PageActions>
          <NewSaveButtonWithPopup
            variant="secondary"
            onClick={() => {
              errors.reset()
              return submit()
            }}
            errorHandler={errors.parse}
          />
          {nextTabButton}
        </PageActions>
      </VStack>
    )
  },
)

type Props = {
  templateId: number | string
  nextTabButton: React.ReactNode
}
export const TemplatesEditor = ({ templateId, nextTabButton }: Props) => (
  <Form
    api={documentTemplatesFormRequest}
    forceParams={{ id: templateId ? String(templateId) : undefined }}
    disableLocalStorageCaching
  >
    <TemplatesEditorFormContent nextTabButton={nextTabButton} />
  </Form>
)
